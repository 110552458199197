/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(232, 240, 243);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3b6c5;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4b7799;
  cursor: pointer;
}
.rmsc .dropdown-container {
  border-radius: 0px !important;
  border: none;
  border-bottom: 1px solid !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  z-index: 999;
}

.rmsc .dropdown-heading-value {
  text-align: start !important;
}
.ages {
  color: black;
}
.child-ages-list-modal {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 99;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  padding: 1rem;
  display: none;
}

.child-ages-list-modal-item {
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.child-ages-list-modal-item-buton {
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: #33658a;
  padding: 0.3rem;
  cursor: pointer;
  color: white;
  width: 5rem;
}

.child-ages-list-modal-item-buton:active {
  border: 1px solid black;
  background-color: #547894;
}

.child-ages-list-modal-item-input {
  outline: none;
  border: none;
  box-shadow: 0 0 2px black;
  border-radius: 2px;
  color: black;
  width: 7rem;
  text-align: center;
  padding: 0.15rem;
}

.child-ages-modal-template {
  max-height: 20rem;
  overflow-y: scroll;
}
.dataPickerForLabel > label.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
  padding: 5px;
  position: absolute;
  top: -5px;
  background: #ffffff;
  line-height: 10px;
}
.child-ages-modal-item-text {
  width: 100%;
  text-align: center;
  letter-spacing: 0.2rem;
  height: 1.2rem;
  padding: 1rem;
  border: 0.1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
  background-color: white !important;
}
@media screen and (min-width: 700px) and (max-width: 1200px) {
  .dashboardTitle {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .dashboardTitle {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1500px) {
  .dashboardTitle {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .dashboardTitle {
    font-size: 14px !important;
  }
}
